import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeImages from "../../composants/layout/fr/LayoutGalerieListeImages"

import { obtenirImages, obtenirPage } from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageGalerieVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "galerieVoieLactee" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: { nin: ["megalaxyZoom", "tresorsCygne", "picDuMidi"] }
        fr: { code_galerie: { eq: "voieLactee" } }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["megalaxyZoom", "tresorsCygne", "picDuMidi"] } }
    ) {
      ...FragmentImagePortrait
    }
  }
`

export default function IndexGalerieVoieLactee() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageGalerieVoieLactee")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return <LayoutGalerieListeImages page={page} images={images} />
}
